.home .promo-popup {
  position: fixed;
  top: -100%;
  max-width: 550px;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  transition: all 0.5s ease-out;
  opacity: 0;
  padding: 24px;
  border-radius: 24px;
  color: #14223d;
  z-index: -1;
  max-height: 95vh; /* Added */
  overflow-y: hidden; /* Added */
}
.home .invalid-promo.show {
  top: 150px !important;
}

.home .promo-popup.show {
  top: 60px;
  opacity: 1;
  z-index: 120;
}

.home .promo-popup .logo {
  display: flex;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: auto;
  margin-bottom: 20px;
}

.home .promo-popup h2 {
  margin-top: -64px;
  color: var(--Primary-Color, #14223d);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
}

.home .separation-line {
  height: 1px;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(177, 177, 177, 0.24);
  margin: 24px 0px !important;
}

.home .promo-popup p {
  color: var(--Primary-Color-2, #6e7b94);
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.48px;
  margin: 0px;
  align-self: center;
}

.home .promo-popup .btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 3px 7px !important;
  background: rgba(35, 34, 34, 0.08);
  color: #14223d;
  font: icon;
  border-radius: 100%;
}
/* 
.home .promo-popup .line {
  width: 80px;
  height: 5px;
  background: #F5CA48;
  margin: 20px auto;
} */

.home .promo-popup .content {
  margin-top: 0;
  text-align: center;
  padding: 0px;
}

.home .promo-popup form {
  margin-top: 30px;
}

.home .promo-popup .store-links {
  margin-top: 32px;
  justify-content: space-between;
}

.app-links {
  gap: 8px !important;
}
.home .promo-popup .btn-signup {
  justify-content: center;
  border-radius: 14px !important;
  padding: 12px 24px !important;
  width: 100%;
}

.home .popup-title {
  color: var(--Primary-Color, #14223d);
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.7px;
  margin: 0px;
}

.home .form-heading {
  color: var(--Primary-Color, #14223d);
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.48px;
  margin: 0px;
}

.home .invalid-form-heading {
  color: var(--Primary-Color, #ff3737) !important;
}

.home .promo-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0px;
}

.home .promo-details .promo-code {
  color: var(--Primary-Color, #14223d);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
}

.home .promo-details .invitor-profile {
  color: var(--Primary-Color, #14223d);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: #fafafa;
  border: 1px solid #ebebeb;
  font-size: 30px;
  font-weight: 700; /* Equivalent to font-semibold */
  border-radius: 50%; /* Rounded circle */
}

.home .promo-details .inviter-name {
  color: var(--Primary-Color, #14223d);
  text-align: left;
  align-self: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.48px;
  margin: 0px 8px;
}

.home .promo-details .code-container {
  padding: 14px 24px;
  background-color: #f7f2db;
  border: 1px solid #ffd000;
  border-radius: 12px;
}

.home .promo-popup form {
  margin-top: 30px;
}

.input-container {
  position: relative;
  margin-bottom: 10px;
}

.form-input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #cacaca;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  background: #f4f7fa;
  transition:
    border-color 0.3s,
    background-color 0.3s;
}

.form-input:focus {
  border-color: #bbbbbb;
}

.form-input::placeholder {
  color: #abaeb5;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.form-label {
  position: absolute;
  top: 0%;
  left: 15px;
  transform: translateY(-50%);
  background-color: #f4f7fa;
  padding: 0 5px;
  color: #14223d;
  transition: all 0.3s;
  pointer-events: none;
  color: var(--Primary-Color, #14223d);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;
}

.form-group {
  display: flex;
  gap: 10px;
  margin: 20px 0 10px 0;
  flex-direction: row;
  flex-basis: auto;
  justify-content: space-between;
}

.form-group .input-container {
  max-width: 50%;
}

.check-group {
  margin-top: -10px;
  margin-bottom: 15px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-size: 14px;
}

.check-group input {
  margin-right: 10px;
  width: auto !important;
}

.check-group img {
  display: inline;
  position: relative;
  top: 5px;
  left: 5px;
}

.register-button {
  padding: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background-color: #14223d;
  border-radius: 10px;
  width: 100%;
  text-transform: none;
}

/* Remove inner border and adjust styling for PhoneInput */
.phone-input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #cacaca;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  background: #f4f7fa;
  display: flex;
  align-items: center;
  width: 100%; /* Make sure it takes the full width */
}

/* Remove the default border around the phone input field inside the component */
.phone-input input {
  border: none;
  outline: none;
  flex-grow: 1;
  background-color: transparent;
  font-size: 16px;
}

/* Align the flag and dropdown with the text */
.phone-input .PhoneInputInternationalIcon {
  margin-right: 10px;
}

/* Placeholder styling for the phone input */
.phone-input input::placeholder {
  color: #abaeb5;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.home .promo-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  opacity: 0;
  z-index: -1;
}

.home .promo-popup-overlay.show {
  opacity: 1;
  z-index: 120;
}

.home .promo-popup .error-message {
  color: red;
  font-weight: bold;
}

@media only screen and (max-width: 1078px) {
  .home .promo-popup h2 {
    margin-top: -30px;
  }

  .prefered-property {
    -webkit-appearance: none;
    width: 100%;
    max-width: 100%;
    background: none;
    color: black;
  }
  .slider-btn-container {
    display: flex;
    flex-direction: column;
    margin-top: -20px;
  }

  .home .promo-popup form {
    flex-direction: column;
    align-items: center;
  }

  .home .promo-popup form > div {
    flex-direction: column;
  }

  .home .promo-popup .PhoneInput {
    margin: 0 !important;
    padding: 0 10px;
  }

  .home .promo-popup form input,
  .home .promo-popup form button {
    width: 100%;
  }
}

@media only screen and (max-width: 1078px) {
  .form-group {
    flex-direction: column;
  }

  .input-container {
    width: 100%; /* Ensures each input takes full width on mobile */
  }

  .form-group .input-container {
    max-width: none;
  }

  .phone-input {
    width: 100%;
    padding: 12px 15px;
    font-size: 16px;
    outline: none;
    transition:
      border-color 0.3s,
      background-color 0.3s;
    display: flex;
    align-items: center;
  }
  .phone-input .PhoneInputInternationalIcon {
    margin-right: 10px;
  }

  .phone-input input {
    padding: 12px 15px;
  }
}
