.services {
  margin-bottom: 50px;
}

.services h2 {
  margin: 0 auto;
  margin-top: 140px;
  margin-bottom: 50px;
  text-wrap: pretty;
  font-size: 42px;
  font-weight: bold;
  max-width: 650px;
  letter-spacing: 1px;
}

.services .section-container {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  max-width: 1200px;
  margin: 0 auto;
}

.services .section {
  box-sizing: border-box;
  padding: 35px;
  background: #F6F7F9;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
}

.services h3 {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
}

.services p {
  font-size: 16px;
  font-weight: 300;
}


@media only screen and (max-width : 1078px) {
  .services .section {
    flex-direction: column;
    margin: 0 20px;
    padding: 20px;
  }

  .services .section:nth-child(odd) {
    flex-direction: column;
  }

  .services h2 {
    margin-top: 100px;
    padding: 0 20px;
  }

}