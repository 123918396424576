.home .get-started {
  color: #ffffff;
  margin-top: 140px;
  background-color: #14223D;
  border-bottom: 1px solid #ffffff22;
  padding-top: 70px;
  padding-bottom: 140px;
  text-align: center;
  margin-left: -20px;
  width: calc(100% + 40px) !important;
}
.fasteasy{
  margin-bottom: 4rem;
}

.home .get-started .get-started-content {
  max-width: 1200px;
  margin: 0 auto;
}

.home .get-started h4 {
  font-size: 42px;
  font-weight: 700;
  color: #ffffff;
}

.home .get-started p {
  font-size: 20px;
  font-weight: 300;
  color: #ffffff;
  letter-spacing: 0;
  max-width: 900px;
  margin: 30px auto;
}

.home .get-started p.yellow {
  color: #f5ca48;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 70px;
}

.home .get-started .btn-started {
  padding: 15px 90px;
  background: #f5ca48;
  color: #14223D;
  font-size: 18px;
  font-weight: 300;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s ease;
  text-decoration: none;
  letter-spacing: 0;
}

@media only screen and (max-width : 1078px) {
  .home .get-started .get-started-content {
    padding: 0 20px;
  }

  .home .get-started .btn-started {
    padding: 15px 40px;
  }
}