body {
  background-color: #f6f7f9;
}

.property {
  max-width: 1200px;
}

.text-preserve-line-breaks {
  white-space: pre-line !important;
}

.property .header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: -10px;
  background: linear-gradient(
      99deg,
      rgba(255, 208, 0, 0) 43.7%,
      rgba(255, 208, 0, 0) 82.26%,
      #ffd000 82.27%,
      #ffd000 100%
    ),
    #14223d;
  padding-top: 20px;
  padding-bottom: 80px;
  padding-left: 20px;
}

.property .header h2 {
  color: #ffffff;
  text-align: center;
  font-size: 50px;
}

.property .header .attributes {
  color: #a0a0a0;
  margin: 0 auto;
  margin-top: 10px;
}

.property .details {
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px 30px;
}

.property-cards {
  margin-bottom: 20px;
}

.property-card {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(94, 100, 115, 0.1);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.002em;
  color: #5e6473;
  margin-bottom: 15px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

/* .property-card .property-tags {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
} */

.property-tag-left {
  background-color: rgba(252, 211, 77, 0.4); /* bg-yellow-400 bg-opacity-40 */
  border: 1px solid #fcd34d; /* border-yellow-400 */
  border-radius: 9999px; /* rounded-full */
  padding: 0.25rem 0.75rem; /* px-3 py-1 */
  font-size: 0.875rem; /* text-sm */
  font-weight: bold; /* font-bold */
  color: black;
  display: flex;
  align-items: center;
  gap: 0.25rem; /* gap-1 */
  position: absolute;
  top: 10px;
  left: 10px;
}

.property-tag-left.green {
  background-color: #05a77988;
  border: 1px solid #015b41;
}

.property-tag-left .dot {
  width: 0.5rem; /* w-2 */
  height: 0.5rem; /* h-2 */
  background-color: #fcd34d; /* bg-yellow-400 */
  border-radius: 9999px; /* rounded-full */
  margin-right: 0.25rem; /* mr-1 */
}

.property-tag-left.green .dot {
  background-color: #034230; /* bg-green-700 */
}

.property-tag-right {
  background-color: #e2e8f0; /* bg-slate-200 */
  border: 1px solid #253256; /* border-dark-blue */
  border-radius: 9999px; /* rounded-full */
  padding: 0.25rem 0.75rem; /* px-3 py-1 */
  font-size: 0.875rem; /* text-sm */
  font-weight: bold; /* font-bold */
  color: black;
  display: flex;
  align-items: center;
  gap: 0.25rem; /* gap-1 */
  position: absolute;
  top: 10px;
  right: 10px;
}

.property-tag-right .dot {
  width: 0.5rem; /* w-2 */
  height: 0.5rem; /* h-2 */
  background-color: #253256; /* bg-dark-blue */
  border-radius: 9999px; /* rounded-full */
  margin-right: 0.25rem; /* mr-1 */
}

/* .property-card:hover {
  filter: contrast(0.7);
} */

.property-card.selected {
  border: 2px solid #14223d;
}

.property-card .property-image {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.property-card .property-attributes {
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  width: 100%;
  justify-content: space-between;
}

.property-card .property-funded-days {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  width: 100%;
  justify-content: center;
  background-color: #ffd000;
  align-items: center;
  font-weight: bold;
  font-size: small;
  align-self: flex-start;
  color: black;
}

.property-content .property-content-funded-days {
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  width: 100%;
  justify-content: left;
  align-items: center;
  font-weight: bold;
  font-size: "13px";
  align-self: flex-start;
  color: #3d8505;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}

.property-card .property-attributes > div {
  align-items: center;
}

.property-card .property-attributes > .property-area {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.property-card .property-attributes > .property-area .property-area-separator {
  border-left: 1px solid #0000001a;
  height: 15px;
}

.property-card .property-attributes > .property-area-value {
  font-weight: 500;
}

.progress-text-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.property-progress {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 170px;
}

.property-progress-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
}

.funded-tag {
  font-size: 14px;
  font-weight: bold;
  align-self: flex-start;
  color: black;
}

.progress-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.property-progress-container {
  position: relative;
  width: 100%;
  border-radius: 8px;
  background-color: #eeeeee;
  height: 7px;
  overflow: hidden;
}

.property-progress-bar {
  height: 100%;
  background: #ffd000;
  border-radius: 8px; /* Adjusted for both ends rounded */
}

.property-progress-text {
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px; /* Add some space between the bar and the text */
}

.property-card .property-content {
  padding: 0 20px 20px 20px;
}

.property-card .property-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #14223d;
  margin-bottom: 10px;
}

.property-card .property-description {
  line-height: 150%;
  letter-spacing: 0.005em;
  color: #14223d;
  max-height: 63px;
  overflow: hidden;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.property-card .property-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.property-card .property-content .property-price > div {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  margin: 20px 0;
  gap: 7px;
  background: #ffffff;
  border: 1px solid #eceff5;
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #14223d;
}
.property-card .property-content .property-price-esitmated > div {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  margin: 10px 0;
  gap: 7px;
  background: #ffffff;
  border: 1px solid #eceff5;
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #14223d;
}
.property-card .property-price-esitmated {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.property-price-circle-yellow {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffd000;
}
.property-price-circle-blue {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #14223d;
}

.property-card .property-price .property-price-circle-yellow {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffd000;
}

.property-card .property-price .property-price-circle-blue {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #14223d;
}

.property-card .property-financials {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px;
  gap: 10px;
  background: #f6f7f9;
  border-radius: 8px;
}

.property-card .property-financials > div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}

.property-card .property-financials .property-financial-value {
  color: #14223d;
  font-weight: 700;
}

.property {
  margin: 50px auto;
}

.card {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: var(--white);
  border: 1.5px solid #e2e8f0;
  border-radius: 8px;
  box-shadow:
    0px 0.25px 1px 0px #0000000a,
    0px 0.6px 2.5px 0px #00000007,
    0px 1.15px 4.75px 0px #00000006,
    0px 2px 8.5px 0px #00000005,
    0px 3.75px 15.85px 0px #00000004,
    0px 9px 38px 0px #00000003;
}

.property h2 {
  font-size: 32px;
  text-align: left;
  margin-top: 50px;
}

.property .images {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}

.property .images img {
  width: 100%;
  height: auto;
}

.property .images .big-image {
  flex: 1 1 50%;
}

.property p {
  margin: 0;
  letter-spacing: 0;
  line-height: 2;
}

.property hr {
  background-color: #e2e8f0;
  border: 0;
  height: 2px;
  margin: 1rem 0;
}

.property .bkg-grey {
  background-color: #f6f7f9;
  padding: 20px;
}

.property .images .small-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 images per row */
  gap: 10px; /* Adjust the gap between grid images */
  flex: 1 1 50%; /* Adjust the size of the grid image area */
  max-height: 408px;
  overflow: auto;
}

.property .docs li {
  list-style: none;
  background-color: #e2e8f0;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: bold;
}

.property .docs li:hover {
  background-color: #14223d;
  color: #ffffff;
}

.property .docs li a {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.property .documents > div {
  cursor: pointer;
  transition: background-color 0.3s;
}

.property .documents > div:hover {
  background-color: var(--light-grey);
}

.property .add-to-cart {
  position: sticky;
  top: 20px;
  align-self: flex-start;
}

.investment-calculator {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.investment-calculator__title {
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #14223d;
  letter-spacing: normal;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: normal;
}

.investment-calculator__subtitle {
  text-align: center;
  font-size: 1.125rem;
  color: #2d3748;
  font-weight: 300;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.investment-calculator__return {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.investment-calculator__details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.investment-calculator__detail {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.investment-calculator__label {
  font-weight: 300;
  color: #2d3748;
  font-size: 0.875rem;
}

.investment-calculator__value {
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #14223d;
  letter-spacing: normal;
  font-weight: bold;
  line-height: normal;
}

.chart {
  /* Add any styles needed for the chart here */
}

.investment-calculator__sliders {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.investment-calculator__slider {
  margin-bottom: 1.5rem;
}

.investment-calculator__slider-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.investment-calculator__disclaimer {
  background-color: #f6f7f9;
  border-radius: 0.375rem;
  text-align: center;
  padding: 1.5rem;
}

.investment-calculator__disclaimer-text {
  font-weight: 300;
  font-size: 0.75rem;
}

.financial-table {
  width: 100%;
  border-spacing: 0 10px;
}

.financial-table td {
  margin-top: 5px;
  margin-bottom: 5px;
}

.roi-label {
  font-size: 0.875rem; /* Equivalent to text-sm */
  font-weight: 800; /* Equivalent to font-extrabold */
}

.roi-value {
  display: flex;
  align-items: center;
  gap: 0.25rem; /* Equivalent to gap-1 */
}

.roi-percentage {
  font-size: 0.875rem; /* Equivalent to text-sm */
  font-weight: 800; /* Equivalent to font-extrabold */
  color: #047857; /* Equivalent to text-green-700 */
  border-right: 1px solid #dfdfdf;
}

.roi-currency {
  color: #000000;
  font-weight: bold;
  padding-left: 10px;
}

.property-details-container {
  display: flex;
  gap: 1rem; /* Equivalent to gap-4 */
  margin: 1rem 0; /* Equivalent to my-4 */
  flex-wrap: wrap;
}

.price-info {
  background-color: rgba(
    252,
    211,
    77,
    0.4
  ); /* Equivalent to bg-yellow-400 bg-opacity-40 */
  border: 1px solid #fcd34d; /* Equivalent to border-yellow-400 */
  border-radius: 9999px; /* Equivalent to rounded-full */
  padding: 0.25rem 0.75rem; /* Equivalent to px-3 py-1 */
  font-size: 0.875rem; /* Equivalent to text-sm */
  font-weight: bold; /* Equivalent to font-bold */
  color: black;
}

.price-info-text {
  display: flex;
  align-items: center;
}

.price-dot {
  width: 0.5rem; /* Equivalent to w-2 */
  height: 0.5rem; /* Equivalent to h-2 */
  background-color: #fcd34d; /* Equivalent to bg-yellow-400 */
  border-radius: 9999px; /* Equivalent to rounded-full */
  margin-right: 0.25rem; /* Equivalent to mr-1 */
}

.rental-info {
  background-color: #e2e8f0; /* Equivalent to bg-slate-200 */
  border: 1px solid #253256; /* Equivalent to border-dark-blue */
  color: black;
  border-radius: 9999px; /* Equivalent to rounded-full */
  padding: 0.25rem 0.75rem; /* Equivalent to px-3 py-1 */
  font-size: 0.875rem; /* Equivalent to text-sm */
  font-weight: bold; /* Equivalent to font-bold */
}

.rental-info-text {
  display: flex;
  align-items: center;
}

.rental-dot {
  width: 0.5rem; /* Equivalent to w-2 */
  height: 0.5rem; /* Equivalent to h-2 */
  background-color: #253256; /* Equivalent to bg-dark-blue */
  border-radius: 9999px; /* Equivalent to rounded-full */
  margin-right: 0.25rem; /* Equivalent to mr-1 */
}

@media (max-width: 1078px) {
  .property-progress-wrapper {
    max-width: 100%;
    padding: 0 10px;
  }
  .property .images {
    flex-direction: column;
  }
  .property .images .big-image,
  .property .images .small-images {
    flex: 1 1 auto;
  }
  .property .images .small-images {
    grid-template-columns: 1fr; /* Stack images vertically on smaller screens */
  }
  .property .docs {
    flex-direction: column;
  }
  .property .docs li a {
    justify-content: flex-start;
  }
}
