.home .calculator {
  max-width: 1200px;
  margin: 0 auto;
}

.home .calculator h2 {
  margin-top: 70px;
}

.home .calculator p {
  text-align: center;
}

.home .calculator .calculator-content {
  display: flex;
  align-items: center;
  gap: 5%;
  margin-top: 70px;
}

.home .calculator .calculator-bkg {
  background: #f5ca48;
  position: absolute;
  left: 0;
  width: 100vw;
  height: 700px;
  z-index: -1;
}

.home .calculator .calculator {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 70px;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
}

.home .calculator .calculator > h3 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 50px;
  margin-top: 0;
}

.home .calculator .calculator-text h2 {
  margin-top: 0;
  text-align: left;
  font-size: 36px;
}

.home .calculator .calculator .calculator-item {
  display: flex;
  flex-direction: column;
}

.home .calculator .calculator .calculator-item .calculator-item-header {
  display: flex;
  justify-content: space-between;
}

.home .calculator .calculator .calculator-item h5 {
  margin-top: 0;
  font-size: 16px;
  font-weight: normal;
  color: #5E6473;
}

.home .calculator .calculator .calculator-item h6 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #5E6473;
}

.home .calculator .calculator .calculator-input-baseline {
  width: 100%;
  height: 8px;
  background: #eeeeee;
  border-radius: 18px;
}

.home .calculator .calculator .calculator-input-valueline {
  height: 8px;
  background: #ffdd00;
  border-radius: 18px;
  margin-top: -8px;
}

.home .calculator .calculator .calculator-input-value {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  background-color: #ffffff;
  width: 22px;
  height: 22px;
  border-radius: 25px;
  color: #f5ca48;
  position: relative;
  top: -15px;
  cursor: pointer;
  user-select: none;
  touch-action: none;
  border: 6px solid #FFD000;
}

.home .calculator .calculator .calculator-input-value:hover {
  border: 2px solid #FFD000;
}

.home .calculator .calculator .calculator-input-value:active {
  border: 2px solid #FFD000;
}


.home .calculator .calculator-text {
  flex: 1;
}

.home .calculator .calculator-text h4 {
  font-size: 34px;
  font-weight: 400;
  letter-spacing: -1px;
}

.home .calculator .calculator-text p {
  font-size: 20px;
  font-weight: 400;
}

.home .calculator .calculator .calculator-summary {
  border: none;
  background: #ffd000;
  padding: 10px 20px;
  width: 100%;
  border-radius: 8px;
}

.home .calculator .calculator .calculator-summary .calculator-summary-header {
  font-size: 20px;
  font-weight: 400;
  padding-right: 20px;
}

.home .calculator .calculator .calculator-summary .calculator-summary-value {
  font-size: 20px;
  font-weight: 700;
  text-align: right;
}

.home .calculator .calculator .calculator-summary .calculator-summary-value.calculator-summary-value-total {
  font-weight: 500;
}

.home .calculator-chart {
  display: flex;
  flex-direction: column;
}

.home .calculator-chart > h2 {
  font-size: 24px;
  text-align: center !important;
  margin-bottom: 50px;
  font-weight: 500;
  color: #5E6473;
}

.home .calculator-chart > h2 b {
  font-weight: 700;
  color: black;
}

.home .calculator-chart .chart-cards {
  display: flex;
  justify-content: space-between;
  gap: 0;
  margin-bottom: 50px;
  padding: 10px 20px;
  border: 1px solid #eeeeee;
  border-radius: 8px;
}

.home .calculator-chart .chart-card h4 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  margin-top: 5px;
  text-align: center;
}

.home .calculator-chart .chart-card h2 {
  font-size: 16px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 5px;
}

.home .calculator-chart .y-bars {
  display: flex;
  height: 280px;
}

.home .calculator-chart .x-axis {
  display: flex;
  margin-left: 53px;
  justify-content: space-between;
  font-size: 12px;
  margin-right: 9px;
}

.home .calculator-chart .bars {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  width: 100%;
}

.home .calculator-chart .bar {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.home .calculator-chart .y-axis {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  gap: 10%;
  margin-right: 10px;
  justify-content: space-between;
}
.home .calculator-chart .bar .investment {
  background: #14223D;
}

.home .calculator-chart .bar .rental {
  background: #ffd000;
}

.home .calculator-chart .bar .property-value {
  background: #22ff7a;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.tooltip {
  position: absolute;
  background: #14223D;
  color: white;
  padding: 20px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

@media only screen and (max-width : 1078px) {
  
  .home .calculator .calculator-content {
    flex-direction: column-reverse;
    padding: 0 20px;
    gap: 40px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .home .calculator .calculator {
    margin-top: 0;
    padding: 30px;
  }

  .home .calculator .calculator-text {
    text-align: center !important;
  }

  .home .calculator .calculator-text h2 {
    text-align: center !important;
  }

  .home .calculator .calculator-text {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

}