.home .hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 40px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.home .hero-content .hero-line {
  width: 80px;
  height: 5px;
  background: #F5CA48;
  margin: 20px 0;
}

.home .hero-content .hero-video {
  width: 100%;
  margin-top: 150px;
  margin-bottom: 350px;
  padding: 20px; 
  box-sizing: border-box; 
  height: auto; 
}

.home .hero-content .hero-video video {
  border-color: #000000;
  border-style: solid;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-radius: 8px;
}

@media only screen and (max-width: 1078px) {
  .home .hero-content .hero-video {
    margin-top: 0;
    margin-bottom: 0;
    padding: 10px; /* Adjust padding for smaller screens */
  }
}

.home .hero-content .hero-img img {
  max-height: 700px;
  margin: 0 40px;
  margin-top: 50px;
}

.home .hero-content .hero-text {
  max-width: 700px;
  margin-top: -50px;
}

.home .hero-content .hero-img {
  margin-top: -30px;
}

.home .hero-content .hero-text h1 {
  font-size: 50px;
  letter-spacing: 0;
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
}

.home .hero-content .hero-text p {
  font-size: 24px;
  letter-spacing: 0;
  font-weight: 400;
  line-height: 1.2;
  color: #818181;
}

.home .hero-content .hero-text p em {
  font-style: normal;
  font-weight: bold;
  color: #000000;
}

.home .hero-content .hero-text .qr {
  margin-top: 30px;
  padding: 10px 30px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  width: fit-content;
  border-radius: 8px;
}

.home .hero-content .hero-text .qr svg {
  width: 180px;
  height: 220px;
}

.home .hero-content .hero-cta {
  display: flex;
  gap: 20px;
  margin-top: 50px;
}

.home .hero-content .store-links {
  margin-top: 30px;
}

.home .hero-content .video-link {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0px;
  margin-top: 30px;
  text-decoration: none;
  color: #14223D;
  margin-bottom: 40px;
}

.home .hero-content .video-link span {
  fill: #14223D;
  background: #F5CA48;
  padding: 8px 6px 0px 12px;
  border-radius: 34px;
  font-size: 24px;
  margin-right: 10px;
  transition: all 0.3s ease;
}

.home .hero-content .video-link span svg {
  width: 30px;
  height: 30px;
}

.home .hero-content .video-link:hover span {
  background-color: #14223D;
  fill: #f5ca48;
  text-decoration: none;
}

@media only screen and (max-width : 1078px) {
  .home .hero-content {
    flex-direction: column;
    padding: 0 20px;
    margin-top: 0;
  }

  .home .hero-content .video-link {
    justify-content: center;
  }

  .home .hero-content .hero-video {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .home .hero-content .hero-img img {
    margin-right: auto;
    margin-left: auto;
  }

  .home .hero-content .hero-text {
    margin-top: 50px;
    text-align: center;
  }

  .home .hero-content .hero-text h1 {
    font-size: 30px;
    /* margin-top: 150px; */
  }

  .home .hero-content .hero-text p {
    font-size: 20px;
  }

  .home .hero-content .hero-text .qr {
    margin: 20px auto 0px auto;
  }

  .home .hero-content .hero-cta {
    flex-direction: column;
    align-items: center;
  }
}