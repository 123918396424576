.home .disclaimer {
  position: fixed;
  top: -100%;
  max-width: 750px;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  transition: all 0.5s ease-out;
  opacity: 0;
  border-radius: 24px;
  color: #14223d;
  /* padding-bottom: 70px; */
  z-index: -1;
  max-height: 95vh; /* Added */
  overflow-y: hidden; /* Added */
  padding: 0 2%;
}

.home .disclaimer.show {
  top: 10%;
  opacity: 1;
  z-index: 121;
}

.home .disclaimer h2 {
  color: var(--Primary-Color, #14223d);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-top: 40px;
}

.home .separation-line {
  height: 1px;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(177, 177, 177, 0.24);
  margin: 20px 30px 0 30px;
}

.home .disclaimer p {
  color: #677897;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.18px;
}

.home .disclaimer .content {
  margin-top: 0;
  padding: 0;
  max-height: 50vh;
}

.home .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  opacity: 0;
  z-index: -1;
}

.home .modal-overlay.show {
  opacity: 1;
  z-index: 120;
}


.home .disclaimer button {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1078px) {
  .home .disclaimer {
    overflow-y: scroll;
  }

  .home .disclaimer.show {
    padding: 0 5%;
  }
}
