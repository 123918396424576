.property-details .calculator {
  max-width: 1200px;
  margin: 0 auto;
}

.property-details .calculator h2 {
  margin-top: 0px;
}

.property-details .calculator p {
  text-align: center;
}

.property-details .calculator .calculator-content {
  display: flex;
  align-items: center;
  gap: 5%;
  margin-top: 0px;
}

.property-details .calculator .calculator-bkg {
  background: #f5ca48;
  position: absolute;
  left: 0;
  width: 100vw;
  height: 700px;
  z-index: -1;
}

.property-details .calculator .calculator {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
}

.property-details .calculator .calculator > h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 50px;
  margin-top: 0;
}

.property-details .calculator .calculator-text h2 {
  margin-top: 0;
  text-align: left;
  font-size: 24px;
}

.property-details .calculator .calculator .calculator-item {
  display: flex;
  flex-direction: column;
}

.property-details .calculator .calculator .calculator-item .calculator-item-header {
  display: flex;
  justify-content: space-between;
}

.property-details .calculator .calculator .calculator-item h5 {
  margin-top: 0;
  font-size: 16px;
  font-weight: normal;
  color: #5E6473;
}

.property-details .calculator .calculator .calculator-item h6 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #5E6473;
}

.property-details .calculator .calculator .calculator-input-baseline {
  width: 100%;
  height: 8px;
  background: #eeeeee;
  border-radius: 18px;
}

.property-details .calculator .calculator .calculator-input-valueline {
  height: 8px;
  background: #ffdd00;
  border-radius: 18px;
  margin-top: -8px;
}

.property-details .calculator .calculator .calculator-input-value {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  background-color: #ffffff;
  width: 22px;
  height: 22px;
  border-radius: 25px;
  color: #f5ca48;
  position: relative;
  top: -15px;
  cursor: pointer;
  user-select: none;
  touch-action: none;
  border: 6px solid #FFD000;
}

.property-details .calculator .calculator .calculator-input-value:hover {
  border: 2px solid #FFD000;
}

.property-details .calculator .calculator .calculator-input-value:active {
  border: 2px solid #FFD000;
}


.property-details .calculator .calculator-text {
  flex: 1;
}

.property-details .calculator .calculator-text h4 {
  font-size: 34px;
  font-weight: 400;
  letter-spacing: -1px;
}

.property-details .calculator .calculator-text p {
  font-size: 20px;
  font-weight: 400;
}

.property-details .calculator .calculator .calculator-summary {
  border: none;
  background: #ffd000;
  padding: 10px 20px;
  width: 100%;
  border-radius: 8px;
}

.property-details .calculator .calculator .calculator-summary .calculator-summary-header {
  font-size: 20px;
  font-weight: 400;
  padding-right: 20px;
}

.property-details .calculator .calculator .calculator-summary .calculator-summary-value {
  font-size: 20px;
  font-weight: 700;
  text-align: right;
}

.property-details .calculator .calculator .calculator-summary .calculator-summary-value.calculator-summary-value-total {
  font-weight: 500;
}

.property-details .calculator-chart {
  display: flex;
  flex-direction: column;
  /* margin-top: -35px; */
  height: 415px;
  padding: 5px;
}

.property-details .calculator-chart > h2 {
  font-size: 16px;
  text-align: center !important;
  margin-top: 16px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #000;
  font-weight: 700;
line-height: normal;
letter-spacing: -0.14px;
padding: 0 12px;
}

.property-details .calculator-chart > h2 b {
  color: #C67C0F;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.14px;
}

.property-details .calculator-chart .chart-cards {
  display: flex;
  justify-content: space-between;
  /* display: inline-flex; */
padding: 5px 20px;
justify-content: center;
gap: 20px;
border-radius: 12px;
background: var(--Primary-Color, #14223D);
height: 100px;
margin-bottom: 20px;
}

.property-details .calculator-chart .chart-card{
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.property-details .calculator-chart .chart-card h4 {
  color: #A2A2A4;
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.24px;
}

.property-details .calculator-chart .chart-card .chart-heading {
  color: var(--Secondary-Color, #F8F8F8);
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.32px;
padding-bottom: 1rem;
}

.property-details .calculator-chart .y-bars {
  display: flex;
  height: 180px;
}

.property-details .calculator-chart .x-axis {
  display: flex;
  margin-left: 53px;
  justify-content: space-between;
  font-size: 12px;
  margin-right: 9px;
}

.property-details .calculator-chart .bars {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  width: 100%;
}

.property-details .calculator-chart .bar {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.property-details .calculator-chart .y-axis {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  gap: 10%;
  margin-right: 10px;
  justify-content: space-between;
}
.property-details .calculator-chart .bar .investment {
  background: #14223D;
}

.property-details .calculator-chart .bar .rental {
  background: #ffd000;
}

.property-details .calculator-chart .bar .property-value {
  background: #22ff7a;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.tooltip {
  position: absolute;
  background: #14223D;
  color: white;
  padding: 20px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

@media only screen and (max-width : 1078px) {
  .property-details .calculator-chart .chart-cards{
    gap:20px;
  }

  .property-details .calculator-chart .chart-card{
    gap:10px;
  }

  .property-details .calculator-chart .chart-card h4{
    font-size: 10px;
  }

  .property-details .calculator-chart .chart-card .chart-heading{
    font-size: 12px;
  }

  .property-details .calculator-chart .y-bars{
    width: 90%;
  }
  .property-details .calculator-chart .x-axis{
    margin: 0;
    font-size: 10px;
    justify-content: space-between;
  }
}

@media only screen and (max-width : 468px) {
  .property-details .calculator-chart .chart-cards{
    gap:10px;
  }
}

@media only screen and (max-width : 1078px) {
  .property-details .calculator-card{
    justify-content: center;
    align-items: center;
  }

  .property-details .calculator-chart .x-axis{
    margin: 0 36px;
    font-size: 12px;
    /* margin-right: 36px; */
  }

  .property-details .calculator-chart .chart-card h4{
    font-size: 12px;
  }

  .property-details .calculator-chart .chart-card .chart-heading{
    font-size: 14px;
  }
}

@media only screen and (max-width : 1078px) {
  
  .property-details .calculator .calculator-content {
    flex-direction: column-reverse;
    padding: 0 20px;
    gap: 40px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .property-details .calculator .calculator {
    margin-top: 0;
    padding: 30px;
  }

  .property-details .calculator .calculator-text {
    text-align: center !important;
  }

  .property-details .calculator .calculator-text h2 {
    text-align: center !important;
  }

  .property-details .calculator .calculator-text {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

}