.intercom-button-container {
  position: fixed; /* Fixes the button at a specific position */
  bottom: 20px; /* Distance from the bottom of the screen */
  right: 20px; /* Distance from the right of the screen */
  cursor: pointer;
  padding: 10px;
  border-radius: 50%; /* Makes the container circular */
  z-index: 999999; /* Ensures it appears above other elements */
  box-shadow: 0 4px 30px rgba(246, 247, 249, 0.2); /* White shadow */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for background color and scale */
}

.intercom-button-container.yellow {
  background-color: #14223D; /* Yellow logo, blue background */
}

.intercom-button-container.blue {
  background-color: #F5CA48; /* Blue logo, yellow background */
}

/* Hover effect */
.intercom-button-container:hover {
  transform: scale(1.1); /* Scale up the button on hover */
  box-shadow: 0 6px 40px rgba(246, 247, 249, 0.3); /* Increase shadow on hover */
}

/* Click effect */
.intercom-button-container:active {
  transform: scale(0.95); /* Scale down the button on click */
  box-shadow: 0 3px 20px rgba(246, 247, 249, 0.2); /* Decrease shadow on click */
}
