.swiper {
    overflow: visible;
    position: relative;
    z-index: 0;
}

.swiper-slide {
    height: auto;
}

.testimonial-slider {
    margin: 0 auto 150px;
    width: 70%;
    position: relative;
}

.testimonial-slider::before {
    content: "";
    position: absolute;
    top: 50px;
    left: -300px;
    height: 100%;
    width: 400px;
    background: linear-gradient(to right, #F6F7F9 0%, #F6F7F9 30%, rgba(246, 247, 249, 0) 100%);
    pointer-events: none;
    z-index: 1;
}

.testimonial-slider::after {
    content: "";
    position: absolute;
    top: 50px;
    right: -300px;
    height: 100%;
    width: 400px;
    background: linear-gradient(to left, #F6F7F9 0%, #F6F7F9 30%, rgba(246, 247, 249, 0) 100%);
    pointer-events: none;
    z-index: 1;
}

.testimonial-slider h2 {
    color: #14223D;
    font-size: 36px;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -1.44px;
    margin-bottom: 60px;
}

.testimonial {
    background: #fff;
    padding: 24px 40px;
    border-radius: 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
    justify-content: space-around;
}

.testimonial p {
    color: #6E7B94;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.32px;
}

.testimonial-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.testimonial-user {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.testimonial-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.testimonial-info h4 {
    margin: 4px 0;
    font-size: 16px;
}

.testimonial-details {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.rating {
    color: #FFD700;
    font-size: 24px;
    display: flex;
    gap: 2px;
}

.testimonial-info p {
    align-self: flex-end;
}

.swiper-pagination-bullets {
    bottom: -40px !important;
}

.swiper-pagination-bullet-active {
    background-color: #14223D;
    border-radius: 7px;
    width: 24px;
}

@media (max-width: 1024px) {
    .testimonial-slider {
        width: 80%;
        margin-bottom: 100px;
    }

    .testimonial-slider h2 {
        font-size: 30px;
        margin-bottom: 50px;
    }

    .testimonial {
        padding: 20px 30px;
    }

    .testimonial-image {
        width: 45px;
        height: 45px;
    }

    .rating {
        font-size: 20px;
    }
}

/* For small screens (mobile, 767px and below) */
@media (max-width: 767px) {
    .testimonial-slider {
        width: 90%;
        margin-bottom: 80px;
    }

    .testimonial-slider h2 {
        font-size: 24px;
        margin-bottom: 40px;
    }

    .testimonial {
        padding: 20px 20px;
    }

    .testimonial-info {
        flex-direction: column;
    }

    .testimonial-user {
        gap: 15px;
        align-items: center;
    }

    .testimonial-image {
        width: 40px;
        height: 40px;
    }

    .rating {
        font-size: 18px;
    }

    .swiper-pagination-bullets {
        bottom: -30px !important;
    }
}

/* For extra small screens (mobile, 575px and below) */
@media (max-width: 575px) {
    .testimonial-slider::before {
        position: static;
    }

    .testimonial-slider::after {
        position: static;
    }

    .swiper-pagination-bullets {
        bottom: -50px !important;
    }
}
