nav {
  width: 100%;
  padding: 0 0 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #eeeeee;
  position: sticky;
  top: 0;
  background: #fffffffb;
  z-index: 100;
}

.trustpilot {
  display: flex;
  justify-content: center;
  background-color: #14223D;
  font-size: 16px;
  color: #ffffff;
  padding: 20px 0;
}

.trustpilot .rating {
  font-weight: bold;
}

.nav-disclaimer {
  background: #14223d;
  color: white;
  font-size: 12px;
  text-align: center;
  padding: 10px 0;
}

nav .content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  width: 100%;
  gap: 20px;
  padding-top: 15px;
  padding-right: 20px;;
  margin: 0 auto;
}

nav .mobile-menu {
  display: none;
}

nav .main-links {
  display: flex;
  gap: 3%;
  width: 100%;
  margin-left: 50px;
}

nav .main-links a, nav .main-links .dropdown-link {
  color: #000000;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 400;
  letter-spacing: 0;
  padding: 15px 10px;
  border-bottom: 2px solid transparent;
  transition: all 0.4s ease;
}

nav .main-links > a.active, nav .main-links .btn-dropdown.active {
  border-bottom: 2px solid #FFD700;
}

nav .main-links .dropdown a.active {
  color: #FFD700;
  border-bottom: none;
}

nav .main-links .dropdown-link .dropdown {
  display: none;
  flex-direction: column;
  position: absolute;
  background: #14223D;
  border-radius: 10px;
  padding: 10px 20px;
  border: 2px solid #14223D;
  margin-top: 10px;
}
nav .main-links .dropdown a {
  padding: 1rem 0.5rem;
}
nav .main-links .dropdown-link.open .dropdown {
  display: flex;
  z-index: 1;
}

nav .main-links .dropdown-link .down-caret {
  position: relative;
  top: -5px;
}

nav .main-links .dropdown-link .up-caret {
  position: relative;
  top: 5px;
}

nav .overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #00000099;
  top: 104px;
  left: 0;
}

.btn-dropdown {
  display: flex;
  /* position: relative; */
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

a.btn-dropdown {
  text-decoration: none;
  color: #000000;
  font-size: 16px;
}

.btn-dropdown .arrow {
  transition: all 0.4s ease;
}

.btn-dropdown:hover .arrow {
  transform: rotate(180deg);
}

.btn-dropdown .dropdown-links {
  display: none;
  position: absolute;
  top: 65px;
  flex-direction: column;
  gap: 10px;
  z-index: 1;
  background: #ffffff;
  padding: 20px 40px;
  box-shadow: 0px 5px 25px rgb(64 57 57 / 30%);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.btn-dropdown:hover .dropdown-links {
  display: flex;
}

.btn-dropdown .dropdown-links a {
  text-decoration: none;
  font-weight: 400;
  color: #000000;
  padding: 0 5px;
  border-left: 3px solid transparent;
  transition: all 0.3s ease;
}

.btn-dropdown .dropdown-links a.active {
  font-weight: 700;
}

.btn-dropdown .dropdown-links a:hover {
  border-left: 3px solid #14223D;
}

@media only screen and (max-width : 1078px) {
  nav {
    padding: 20px;
    border-bottom: none;
  }

  nav .mobile-menu {
    display: block;
  }

  nav .main-links {
    display: none;
  }

  nav .main-links.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 73px;
    background: #ffffff;
    width: 90vw;
    padding: 30px;
    z-index: 999;
    gap: 20px;
    animation: fadeInLeft 0.3s ease-out;
    box-shadow: 0px 5px 25px rgb(64 57 57 / 30%);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-left: 0;
  }

  nav .main-links a, nav .main-links .dropdown-link {
    padding: 5px 10px;
  }

  .btn-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .btn-dropdown .dropdown-links {
    position: static;
    top: unset;
  }
}
