html {
  min-height: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Plus Jakarta Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 200;
  letter-spacing: 0.8px;
  min-height: 100%;
  color: #14223D;
  position: relative;
}

.content {
  width: 100%;
  overflow-x: hidden;
  padding: 0 20px;
}
.error-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:root {
  --animate-delay: 0.5s;
}

h2 {
  text-align: center;
  margin-top: 140px;
  font-weight: 700;
  font-size: 50px;
}

.bkg-img {
  position: absolute;
  top: 0px;
  z-index: -1;
  width: 100vw;
  overflow: hidden;
}

.bkg-img.bottom {
  bottom: 0;
}

.bkg-img svg {
  width: 2000px;
  animation: fadeInUp 3s ease-out;
  overflow: hidden;
  margin: 0 auto;
}

.bkg-img-2 {
  position: absolute;
  top: 1600px;
  z-index: -1;
  width: 100vw;
  overflow: hidden;
}

.bkg-img-2 svg {
  width: 100vw;
  /* animation: fadeInLeft 3s ease-out, breathe 10s infinite; */
}



.text-grey {
  color: var(--darker-grey);
}

.text-yellow {
  color: var(--yellow);
}

.text-dark-yellow {
  color: var(--dark-yellow);
}

.text-white {
  color: var(--white);
}

.text-blue {
  color: var(--blue);
}

.text-red {
  color: var(--red);
}

.text-sm {
  font-size: 0.875rem;
}

.text-xs {
  font-size: 0.75rem;
}

.text-lg {
  font-size: 1.25rem;
}

.text-xl {
  font-size: 2.25rem;
}

.text-light {
  font-weight: 300;
}

.text-bold {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

@keyframes breathe {
  50% {
    transform: scaleX(1.12);
  }
}

@media only screen and (max-width : 1078px) {
  h2 {
    font-size: 36px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .content {
    padding: 0;
  }

  .bkg-img svg {
    margin-top: 80px;
  }

  .bkg-img-2 {
    display: none;
  }

  .bkg-img-2 svg {
    width: 400vw;
    margin-left: -300px;
  }
}
