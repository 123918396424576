.home .growth {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.home .growth > div {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 43px 36px 43px;
  gap: 10px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.home .growth > div > h3 {
  font-size: 18px;
  font-weight: 700;
}

.home .growth > div > p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  font-weight: 400;
}

@media only screen and (max-width : 1078px) {
  .home .growth {
    flex-direction: column;
    padding: 0 20px;
  }

  .home .growth > div {
    height: auto;
  }
}