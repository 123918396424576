.home .investment-process h2 {
  margin-top: 150px;
}

.home .investment-process .how-section {
  background: #14223D;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 100px;
  margin-top: 70px;
  margin-left: -20px;
  width: calc(100% + 40px) !important;
}

.home .investment-process .how-section .how-section-content {
  display: flex;
  overflow: visible;
  margin: 0 auto;
  gap: 40px;
}

.home .investment-process .how-section .how-section-inner-content {
  display: flex;
  gap: 20px;
  margin-top: 90px;
  margin-bottom: 150px;
}

.home .investment-process .how-section .how-section-inner-content svg {
  width: 100px;
  fill: #f5ca48;
}

.home .investment-process .how-section .icon-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.home .investment-process .how-section .how-section-inner-content {
  max-width: 739px;
}

.home .investment-process .how-section .how-section-text h4 {
  font-weight: 500;
  font-size: 36px;
  color: white;
  letter-spacing: -1px;
}

.home .investment-process .how-section .how-section-text p {
  font-weight: 400;
  font-size: 24px;
  line-height: 125%;
  letter-spacing: 0px;
  margin-top: 16px;
  color: white;
}

.home .investment-process .how-section .how-section-text p.yellow {
  color: #ffdd00;
}

.home .investment-process .how-section .how-section-content img {
  height: 500px;
  margin-bottom: -200px;
  margin-top: -50px;
  min-width: 340px;
  object-fit: cover;
  object-position: top;
}

.home .investment-process .tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 70px;
}

.home .investment-process .tabs .tab {
  background-color: #F6F7F9;
  padding: 15px 70px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s ease;
}

.home .investment-process .tabs .tab:not(.selected):hover {
  background: #14223D;
  color: #ffffff;
  font-weight: 700;
}

.home .investment-process .tabs .tab.selected {
  background: #f5ca48;
  font-weight: 700;
}

@media only screen and (max-width : 1078px) {

  .home .investment-process {
    margin-top: 140px;
  }

  .home .investment-process h2 {
    margin-top: 310px;
  }

  .home .investment-process .how-section {
    padding: 0 20px;
    margin-top: 70px;
  }

  .home .investment-process .how-section .how-section-content .number {
    margin: 0 auto;
  }

  .home .investment-process .how-section h4 {
    margin-top: 20px;
  }

  .home .investment-process .how-section .how-section-content img {
    height: 550px;
    object-fit: cover;
    object-position: top;
    margin-bottom: 10px;
    margin-top: -90px;
    width: 340px;
    margin-right: auto;
    margin-left: auto;
  }

  .home .investment-process .how-section .how-section-content {
    padding: 40px 0;
    flex-direction: column-reverse;
    text-align: center;
  }

  .home .investment-process .how-section .how-section-inner-content {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 50px;
  }

  .home .investment-process .tabs .tab {
    padding: 10px 30px;
  }

  .home .investment-process .tabs {
    gap: 10px;
    margin-top: 30px;
  }
  
}