.home .marketplace-samples {
  background: #F6F7F9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 70px;
  padding-top: 140px;
}

.home .marketplace-samples h2 {
  margin-top: 0px;
  margin-bottom: 70px;
}

.home .marketplace-samples h4 {
  font-size: 50px;
  font-weight: 500;
  letter-spacing: -2px;
  margin-bottom: 30px;
}

.home .marketplace-samples h3 {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -1px;
  margin-bottom: 40px;
}

.home .marketplace-samples .marketplace-samples {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width : 1078px) {
  .home .marketplace-samples h2 {
    margin-top: 70px;
    margin-bottom: 0px;
  }

  .home .marketplace-samples {
    margin-left: 0;
    padding-top: 0;
  }
}
