.home .compliance {
  background: #F6F7F9;
  position: absolute;
  left: 0;
  width: 100vw;
  z-index: -1;
  margin-top: -100px;
}

.home .compliance .logos {
  padding-top: 125px;
  padding-bottom: 25px;
  padding-left: 5%;
  padding-right: 5%;
  margin: 0 auto;
}

@media only screen and (max-width : 1078px) {
  .home .compliance {
    margin-top: 0;
    padding: 50px 20px;
  }

  .home .compliance .logos {
    align-items: center;
    padding: 0 40px;
  }
}