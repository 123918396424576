/* Modal backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 101;
}

/* Modal container */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the modal */    max-height: 90vh;
    overflow-y: auto;
    z-index: 102;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 520px;
    padding: 30px;
}

/* Modal header */
.modal-header {
    display: flex;
}

/* Modal title */
.modal-title {
    font-weight: 600;
    margin: 0;
}

/* Modal close button */
.modal-close {
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.modal-close:hover {
    border: 1px solid #eaeaea;
}

/* Modal content */
.modal-content {
    max-height: calc(90vh - 60px); /* Adjust height considering padding/header */
    overflow-y: auto;
}
