footer {
  background-color: #14223D;
  color: #ffffff;
  padding: 2% 0;
}

footer .footer-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

footer .rows {
  display: flex;
  gap: 50px;
}

footer .logo {
  display: flex;
  justify-content: space-between;
}

footer .logo a {
  text-decoration: none;
}

footer .logo a:hover {
  text-decoration: underline;
}

footer .links {
  display: flex;
  flex-direction: column;
}

footer .links a {
  text-decoration: none;
  font-size: 14px;
  color: #ffffffaa;
  margin-bottom: 5px;
}

footer .copyright {
  font-size: 12px;
}

footer .content {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

footer h4 {
  font-size: 35px;
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 0;
}
footer h4 b {
  font-weight: 500;
  font-size: 24px;
}

footer .address, footer .contact, footer .hours {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

footer .logos {
  flex: 1;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

footer h3 {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 400;
}

footer p {
  font-size: 14px;
  line-height: 2;
  color: #ffffffaa;
  margin-top: 0;
}

footer .info {
  display: flex;
}

footer .info a {
  font-size: 12px;
  color: #ffffff88;
  text-decoration: none;
  padding-right: 10px;
}

footer .info a:not(:first-child) {
  padding-left: 10px;
}

footer .info a:not(:last-child) {
  border-right: 1px solid #ffffff33;
}

footer .info a:hover {
  text-decoration: underline;
}

footer .disclaimer {
  font-size: 12px;
  margin-top: 20px;
  color: #ffffff88;
  border-top: 1px solid #ffffff11;
  padding-top: 20px;
}

.cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #14223d;
  width: 100%;
  color: white;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid #8e94a1;
  box-shadow: 0px -2px 10px 0px #00000033;
  z-index: 50;
}

.cookie-bar-content {
  display: flex;
  gap: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.cookie-bar-buttons {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.cookie-bar-buttons button {
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cookie-bar-buttons button.btn-link {
  background: transparent;
  color: white;
  text-decoration: underline;
}

@media only screen and (max-width : 1078px) {
  footer {
    flex-direction: column;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  footer .logo {
    flex-direction: column;
    gap: 10px;
  }

  footer .logo img:last-child {
    display: none;
  }

  footer .logo h4 {
    text-align: center;
  }

  footer .rows {
    flex-direction: column;
    gap: 20px;
  }

  footer h4 {
    font-size: 25px;
  }

  footer .info {
    flex-direction: column;
    gap: 5px;
  }

  footer .info a:not(:first-child) {
    padding-left: 0px;
  }
  
  footer .info a:not(:last-child) {
    border-right: 0px solid #ffffff33;
  }

  footer h4 b {
    font-size: 18px;
  }

  footer .address, footer .contact, footer .hours {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .cookie-bar-buttons {
    flex-direction: column;
    justify-content: center;
  }
  
}