.faqs {
  margin-bottom: 50px;;
}

.faqs h2 {
  margin-top: 100px;
  margin-bottom: 50px;
  font-size: 42px;
  letter-spacing: 1px;
}

.faqs h4 {
  margin-top: 0;
  cursor: pointer;
  letter-spacing: 0;
  font-size: 18px;
}

.faqs p {
  animation: fadeInDown .5s;
}

.faqs h4:hover {
  text-decoration: underline;
}

.faqs .section {
  box-sizing: border-box;
  background: #F6F7F9;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  padding: 25px 35px;
  transition: all 0.2s ease-out;
  margin: 0 auto;
  margin-top: 25px;
  max-width: 1200px;
}

.faqs .section.collapsed {
}

.faqs .section.collapsed p {
  display: none;
}

@media only screen and (max-width : 1078px) {
  .faqs .section {
    margin: 10px 20px;
  }
}
