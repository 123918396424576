.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 70px;
  margin-bottom: 70px;
}

.carousel .property-card  {
  transition: all 1s ease;
  cursor: pointer;
  width: 400px;
  max-width: calc(100vw - 20px);
}

@media screen and (max-width: 1078px) {
  .carousel {
    flex-direction: column;
    gap: 20px;
  } 
}