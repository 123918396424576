.home .coming-soon {
  position: fixed;
  top: -100%;
  max-width: 550px;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  transition: all 0.5s ease-out;
  opacity: 0;
  background: linear-gradient(
      -20deg,
      rgba(255, 208, 0, 0) 43.7%,
      rgba(255, 208, 0, 0) 72.26%,
      #14223d 72.27%,
      #14223d 100%
    ),
    #f6f7f9;
  border-radius: 24px;
  color: #14223d;
  padding-bottom: 70px;
  z-index: -1;
  max-height: 95vh; /* Added */
  overflow-y: hidden; /* Added */
}

.home .coming-soon.show {
  top: 10px;
  opacity: 1;
  z-index: 120;
}

.home .coming-soon .logo {
  display: flex;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: auto;
  margin-bottom: 20px;
}

.home .coming-soon h2 {
  margin-top: -64px;
  color: var(--Primary-Color, #14223d);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
}

.home .separation-line {
  height: 1px;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(177, 177, 177, 0.24);
  margin: 20px 30px 0 30px;
}

.home .coming-soon p {
  color: #677897;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.18px;
}

.home .coming-soon .btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
}
/* 
.home .coming-soon .line {
  width: 80px;
  height: 5px;
  background: #F5CA48;
  margin: 20px auto;
} */

.home .coming-soon .content {
  margin-top: 0;
  padding: 0 50px;
  text-align: center;
}

.home .coming-soon form {
  margin-top: 30px;
}

.home .coming-soon .store-links {
  margin-top: 20px;
  justify-content: center;
}

.home .form-heading {
  color: var(--Primary-Color, #14223d);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.6px;
}

.home .coming-soon form {
  margin-top: 30px;
}

.input-container {
  position: relative;
  margin-bottom: 10px;
}

.form-input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #cacaca;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  background: #f4f7fa;
  transition: border-color 0.3s, background-color 0.3s;
}

.form-input:focus {
  border-color: #bbbbbb;
}

.form-input::placeholder {
  color: #abaeb5;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.form-label {
  position: absolute;
  top: 0%;
  left: 15px;
  transform: translateY(-50%);
  background-color: #f4f7fa;
  padding: 0 5px;
  color: #14223d;
  transition: all 0.3s;
  pointer-events: none;
  color: var(--Primary-Color, #14223d);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;
}

.form-group {
  display: flex;
  gap: 10px;
  margin: 20px 0 10px 0;
  flex-direction: row;
  flex-basis: auto;
  justify-content: space-between;
}

.form-group .input-container {
  max-width: 50%;
}

.check-group {
  margin-top: -10px;
  margin-bottom: 15px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-size: 14px;
}

.check-group input {
  margin-right: 10px;
  width: auto !important;
}

.check-group img {
  display: inline;
  position: relative;
  top: 5px;
  left: 5px;
}

.register-button {
  padding: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background-color: #14223d;
  border-radius: 10px;
  width: 100%;
  text-transform: none;
}

/* Remove inner border and adjust styling for PhoneInput */
.phone-input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #cacaca;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  background: #f4f7fa;
  display: flex;
  align-items: center;
  width: 100%; /* Make sure it takes the full width */
}

/* Remove the default border around the phone input field inside the component */
.phone-input input {
  border: none;
  outline: none;
  flex-grow: 1;
  background-color: transparent;
  font-size: 16px;
}

/* Align the flag and dropdown with the text */
.phone-input .PhoneInputInternationalIcon {
  margin-right: 10px;
}

/* Placeholder styling for the phone input */
.phone-input input::placeholder {
  color: #abaeb5;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.home .coming-soon-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  opacity: 0;
  z-index: -1;
}

.home .coming-soon-overlay.show {
  opacity: 1;
  z-index: 120;
}

.home .coming-soon .error-message {
  color: red;
  font-weight: bold;
}

@media only screen and (max-width: 1078px) {
  .home .coming-soon h2 {
    margin-top: -30px;
  }

  .prefered-property {
    -webkit-appearance: none;
    width: 100%;
    max-width: 100%;
    background: none;
    color: black;
  }
  .slider-btn-container {
    display: flex;
    flex-direction: column;
    margin-top: -20px;
  }

  .home .coming-soon form {
    flex-direction: column;
    align-items: center;
  }

  .home .coming-soon form > div {
    flex-direction: column;
  }

  .home .coming-soon .PhoneInput {
    margin: 0 !important;
    padding: 0 10px;
  }

  .home .coming-soon .btn {
    margin-top: 15px;
  }

  .home .coming-soon form input,
  .home .coming-soon form button {
    width: 100%;
  }
}

@media only screen and (max-width: 1078px) {
  .home .coming-soon {
    overflow-y: scroll;
  }
  .form-group {
    flex-direction: column;
  }

  .input-container {
    width: 100%; /* Ensures each input takes full width on mobile */
  }
  
  .form-group .input-container {
    max-width: none;
  }

  .phone-input {
    width: 100%;
    padding: 12px 15px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s, background-color 0.3s;
    display: flex;
    align-items: center;
  }
  .phone-input .PhoneInputInternationalIcon {
    margin-right: 10px;
  }

  .phone-input input {
    padding: 12px 15px;
  }
}
