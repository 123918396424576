.home .join {
  background: #F6F7F9;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
  padding-bottom: 70px;
  margin-top: 70px;
}

.home .join h4 {
  color: #000000;
  font-size: 52px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
}

.home .join .join-features {
  display: flex;
  max-width: 1200px;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  margin-top: 100px;
  color: #000000;
}

.home .join .join-features > div {
  background-color: #F6F7F9;
  padding: 35px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
}
.home .join .join-features > div > svg {
  height: 50px;
  width: 50px;
}

.home .join .join-features > div > h5 {
  font-size: 22px;
  line-height: 34px;
  text-wrap: pretty;
  margin: 10px 0;
  font-weight: 700;
}

.home .join .join-features > div > p {
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width : 1078px) {
  
  .home .join {
    margin-bottom: 0;
    padding-bottom: 100px;
  }
  .home .join .join-features {
    flex-direction: column;
    padding: 0 20px;
    margin-top: 50px;
  }
  
  .home .join .join-features > div {
    padding: 20px;
  }
  .home .join h4 {
    text-align: center;
    margin-top: 50px;
    font-size: 36px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .home .join .join-features > div > h5 {
    height: auto;
    margin: 0;
  }
}