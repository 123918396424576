.blur {
    filter: blur(5px);
    pointer-events: none;
  }
  

  .property-comingsoon{
    color: #D1B020;
    padding-top: 16px;
    padding-bottom: 16px;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;

  }