.marketplace {
  margin: 10px;
  margin-bottom: 50px;
  background: #F6F7F9;
  padding: 20px;
  border-radius: 8px;
}

.marketplace .header {
  background: linear-gradient(99deg, rgba(255, 208, 0, 0) 43.7%, rgba(255, 208, 0, 0) 82.26%, #FFD000 82.27%, #FFD000 100%), #14223D;
}

.marketplace .content {
  display: flex;
  gap: 30px;
  padding: 0;
}

.property-card-blur {
  /* Hide marketplace until real properties are added */
  filter: blur(5px);
  user-select: none;
}

.marketplace .property-cards {
  flex: 4;
  border-width: 1px;
  overflow-y: auto;
  max-height: 800px;
  overflow: auto;
}

.marketplace .property-cards.grid {
  flex: none;
  display: grid;
  grid-gap: 2rem;
  justify-items: flex-start;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  -webkit-box-align: stretch;
  place-items: stretch center;
  max-height: none;
  width: 100%;
}

.marketplace .property-card {
  width: 100%;
  max-width: 390px;
  min-height: auto;
  transition: all 0.1s ease-in;
  cursor: pointer;
}

/* .marketplace .property-card:hover {
  margin-top: -10px;
} */

.marketplace .map {
  height: 800px;
  flex: 10;
}

.marketplace .map>div {
  border-radius: 12px;
}

.marketplace .filter-container {
  display: flex;
  margin-top: -29px;
  margin-bottom: 40px;
}

.marketplace .tabs-container {
  display: flex;
  flex: 1;
  justify-content: center;
}

.marketplace .tabs {
  background-color: #FFFFFF;
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.04), 0px 0.6px 2.5px rgba(0, 0, 0, 0.027), 0px 1.15px 4.75px rgba(0, 0, 0, 0.024), 0px 2px 8.5px rgba(0, 0, 0, 0.02), 0px 3.75px 15.85px rgba(0, 0, 0, 0.016), 0px 9px 38px rgba(0, 0, 0, 0.01);
  border-radius: 9999px;
  max-width: 600px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 16px;
  justify-content: space-between;
  margin-left: 167px;
}

.marketplace .tabs .tab {
  background: #FFFFFF;
  border-radius: 9999px;
  padding: 10px 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.marketplace .tabs .tab.active {
  background: #FFD000;
  font-weight: bold;
}

.marketplace .tabs .tab:hover {
  background: #14223D;
  color: #ffffff;
}

.marketplace .dropdown {
  padding: 10px 20px;
  border-radius: 100px;
  background: #14223D;
  color: #ffffff;
  font-weight: bold;
  border: 5px solid white;
  margin-right: 10px;
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAGlQTFRFAAAA/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////ButdgAAACN0Uk5TAC+su5UN2f+N6qVX/O8u+/A3WPE4YP30PmE/QGL1Zv46cSODITcUAAAAhUlEQVR4nO3QMQuDQAwF4PdQBNHhECoIIv7/H+WmOAjSDiKWioUqF3PaoZvQNwW+JBeO+Br++XcmVR/5FBw4WzgKDmfN3iA4+uyyCfiQp0X+KDVkv788mcR8zA57xo33rTRsoRnZa3XjNXB5c+PXOGLk09vT2ar61IKtfddllGSFc9a5Li8wSBofQrHPnwAAAABJRU5ErkJggg==);
  background-position: 100%;
  background-repeat: no-repeat;
  padding-right: 35px;
}

.marker {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #14223D;
  border-width: 4px;
  border-color: #ffcf00;
  border-style: solid;
  cursor: pointer;
  padding: 10px;
  transition: all 0.3s ease;
}

.marker img {
  width: 24px;
}

.marker .text {
  margin-top: 16px;
  text-align: center;
  background: rgb(20 34 60);
  padding: 4px 10px;
  width: 100px;
  left: -25px;
  position: absolute;
  opacity: 0.8;
  color: white;
}

.marker:hover {
  transform: scale(1.2);
}

.marker:hover .text {
  background-color: #14223D;
  border-color: #FFD000;
  color: #ffffff;
}

.custom-marker {
  position: relative;
  display: inline-block;
  text-align: center;
}

.ellipse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Prevent ellipses from interfering with hover/clicks */
}

.ellipse-1 {
  width: 80px;
  height: 80px;
  z-index: 0;
}

.ellipse-2 {
  width: 115px;
  height: 115px;
  z-index: 0;
}

.ellipse-3 {
  width: 140px;
  height: 140px;
  z-index: 0;
}

.marker-icon {
  z-index: 20;
  width: 32px; /* Adjust size as necessary */
  height: 35px; /* Adjust size as necessary */
  isolation: isolate;
}

.marker-label {
  margin-top: 8px;
  background: #14223D;
  color: #fff;
  padding: 12px 14px;
  border-radius: 7px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 85px; /* Adjust based on marker size */
  white-space: nowrap;
  color: var(--Secondary-Color, #F8F8F8);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.28px;
}

.custom-marker:hover .marker-label {
  background-color: #FFD000;
  color: #14223D;
}


@media screen and (max-width: 1500px){
  .marketplace .property-cards.grid {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }
}

@media screen and (max-width: 1280px){
  .marketplace .property-cards.grid {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
}

@media screen and (max-width: 1078px) {
  .marketplace .property-cards {
    display: grid;
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    grid-gap: 2rem;
    justify-items: flex-start;
    -webkit-box-align: stretch;
    place-items: stretch center;
    max-height: none;

  }
  .marketplace .header {
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 8px;
    background: #14223D;
  }
  .marketplace .content {
    flex-direction: column-reverse;
  }

  .marketplace .filter-container {
    flex-direction: column;
  }

  .marketplace .tabs {
    border-radius: 24px;
    margin-left: 0;
    margin-bottom: 10px;
    width: 97%;
  }

  .marketplace .tabs .tab {
    padding: 7px 5%;
  }

  .marketplace .property-cards.grid {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }

}

@media screen and (max-width: 768px) {
  .marketplace .property-cards {
    display: grid;
    grid-template-columns: repeat(1, minmax(0px, 1fr));
    grid-gap: 2rem;
    justify-items: flex-start;
    -webkit-box-align: stretch;
    place-items: stretch center;
    max-height: none;
  }

  .marketplace .tabs {
    justify-content: space-evenly;
  }
}