/* Static pages such as privacy and terms and conditions */
.static {
  margin-bottom: 50px;
}

.static .text-content {
  max-width: 1200px;
  margin: 0 auto;
}

.static h2 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.static p, .static li {
  font-weight: 400;
}

.static table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-weight: 400;
}

.static table th {
  text-align: left;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.static table td {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e0e0e0;
}


@media only screen and (max-width : 1078px) {
  .static {
    padding: 20px;
  }
}