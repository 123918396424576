.submit h2 {
  margin-top: 100px;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 1px;
}

.submit > p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  max-width: 770px;
  text-wrap: pretty;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.submit form {
  padding: 35px;
  max-width: 700px;
  background: #FFFFFF;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 0 auto;
}

.submit form h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 0px;
}

.submit form input, .submit form select {
  box-sizing: border-box;
  padding: 15px;
  border: 1px solid #D3D9E5;
  border-radius: 8px;
  width: 100%;
}

.submit form label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 0px;
  margin-top: 10px;
}

.submit form .hint {
  font-size: 0.85rem;
  font-style: italic;
  color: #4d4d4d;
}

.submit form .info-message {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  background-color: #f9f9f9;
}

.submit form .error-message {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  background-color: #f9f9f9;
  color: #ff0000;
}

.submit form .actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


@media only screen and (max-width : 1078px) {
  .submit h2 {
    padding: 0 20px;
  }

  .submit p {
    padding: 0 30px;
  }

  .submit form {
    margin: 0 20px;
    width: 100%;
    max-width: none;
  }
}