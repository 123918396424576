@import url("property/calculator.css");

body {
  background-color: #f6f7f9;
}

.property-details {
  max-width: 1400px;
}

.text-preserve-line-breaks {
  white-space: pre-line !important;
}

.property-details .header {
  margin-top: -50px;
}

.property-details .property-heading {
  color: black;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  margin-bottom: 50px;
}

.property-details .section-container {
  display: flex;
  flex-direction: row;
  padding: 8px 2px;
  gap: 16px;
}

.property-details .small-images {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 15%;
  max-height: 605px;
  overflow-x: auto;
  scroll-behavior: smooth;
  overflow-y: auto; /* Adds a scrollbar if images overflow */
}

.property-details .small-image {
  width: 100%;
  height: 128px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}

.property-details .small-video {
  width: 100%;
  height: 128px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f3f4f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.property-details .small-image.selected {
  border: 3px solid var(--Primary-Color, #14223d);
}
.property-details .small-video.selected {
  border: 3px solid var(--Primary-Color, #14223d);
}

.property-details .more-images {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 128px;
  background-color: #f3f4f6;
  border-radius: 8px;
  color: #6b7280;
}

.property-details .more-images-container {
  position: relative;
}

.property-details .more-images-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 16px;
  border-radius: 8px;
}

.property-details .big-image-container {
  position: relative;
  width: 56%;
}
.property-details .tokens-indicator {
  position: absolute;
  top: 20px;
  right: 10px;
  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  border: 1px solid #253256;
  background: #e6eaf4;
  z-index: 40;
  color: var(--Primary-Color, #14223d);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.property-details .property-price {
  color: var(--Secondary-Color, #f8f8f8);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-right: 3rem;
}

.property-details .property-price-value {
  color: var(--Secondary-Color, #f8f8f8);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
}

.property-details .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.property-details .big-image {
  width: 100%;
  height: 605px;
  object-fit: cover;
  border-radius: 20px; /* Match with the gradient overlay */
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;
}
/* 
.property-details .gradient-image{
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 0;
} */

.property-details .gradient-overlay {
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%;
  background-size: cover;
  height: 100%;
  background: linear-gradient(
    182deg,
    rgba(20, 34, 61, 0) 1.6%,
    rgba(20, 34, 61, 0) 46.26%,
    #14223d 98.69%
  );
  border-radius: 0 0 20px 20px;
}

.property-details .image-details {
  position: absolute;
  bottom: 4%;
  left: 32px;
  color: #f8f8f8;
  padding: 10px 6px;
  border-radius: 8px;
  z-index: 40;
  width: 100%;
}

.property-details .separation-line {
  height: 1px;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(177, 177, 177, 0.24);
}
.property-details .property-title {
  font-size: 26px;
  font-weight: bold;
  color: #f8f8f8;
  margin-bottom: 4px;
}

.property-details .property-info {
  font-size: 16px;
  margin: 2px 0 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.property-details .sub-info {
  margin-right: 8px;
  font-weight: 400;
}

.property-details .detail-cards {
  width: 29%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.property-details .card {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.property-details .finance-card {
  padding: 16px;
  border-radius: 16px;
}

.property-details .finance-table-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  margin: 8px 2px;
}

.property-details .finance-table-value {
  color: var(--Primary-Color, #14223d);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}

.property-details .yellow-card {
  background-color: #ffd000;
  padding: 16px;
}

.property-details .property-details-roi-card {
  background: #ffd000;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  color: #14223d; /* Default text color */
  font-family: Arial, sans-serif; /* Change to your preferred font */
}

.property-details .property-details-roi-card h3 {
  color: var(--Primary-Color, #000000);
  font-family: "Plus Jakarta Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}

.property-details .property-details-roi-card p {
  color: #000000;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.14px;
  max-height: 4.2em; /* 3 lines of text with line-height: 1.5 */
  white-space: normal; /* Allows text to wrap normally */
  overflow-y: auto;
}

.property-details .property-details-roi-card .roi-container,
.property-details .property-details-roi-card .funded-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffeb94;
  border: 1px solid var(--Primary-Color, #14223d);
  border-radius: 12px;
  padding: 12px 16px;
  width: 100%;
}

.property-details .property-details-roi-card .roi-content,
.property-details .property-details-roi-card .funded-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.property-details .property-details-roi-card .roi-content span,
.property-details .property-details-roi-card .funded-content span {
  color: var(--Primary-Color, #14223d);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  font-family: "Plus Jakarta Sans";
}

.property-details .property-details-roi-card .roi-percentage,
.property-details .property-details-roi-card .funded-days {
  color: var(--Primary-Color, #14223d);
  font-family: "Plus Jakarta Sans";
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}

.property-details .calculator-card {
  display: flex;
  width: 100%;
  border-radius: 16px;
  border: 1px solid #ececec;
  background: #fff;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 40px; */
}

.property-details .calculator-card-title {
  color: var(--Secondary-Color, #f8f8f8);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  margin-top: 15px;
}

.property-details .calculator-card-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

.property-details .calculator-card-content-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.property-details .calculator-card-content-row-value {
  color: var(--Secondary-Color, #f8f8f8);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.13px;
}

.property-details .investment-calculations {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border-radius: 12px;
  background: #29364e;
}

.property-details .projected-investment {
  display: flex;
  padding: 11px 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Brand-Color, #ffd000);
}

.property-details .projected-investment-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.property-details .projected-investment-key {
  color: var(--Primary-Color, #14223d);
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-style: normal;
  width: 75%;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}

.property-details .projected-investment-value {
  color: var(--Primary-Color, #14223d);
  font-family: "Plus Jakarta Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.13px;
}

.property-details .investment-calculations-heading {
  color: var(--Secondary-Color, #f8f8f8);
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  align-self: stretch;
}

.property-details .investment-content-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.property-details .investment-content-row-key {
  color: var(--Secondary-Color, #f8f8f8);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}
.property-details .investment-content-row-value {
  color: #d6d6d6;
  text-align: right;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.13px;
}

.property-details .card-title-container {
  display: flex;
  padding: 8px;
  border: 1px solid #14223d;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.25);
}

.property-details .card-title {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: capitalize;
  color: #14223d;
}

.property-details .card-content {
  margin-top: 8px;
}

.property-details .bold {
  font-weight: bold;
}

.property-details .progress-bar {
  background-color: #e5e7eb;
  border-radius: 9999px;
  height: 10px;
  margin: 16px 0;
}

.property-details .progress {
  background-color: #3b82f6;
  height: 10px;
  border-radius: 9999px;
  width: 80%;
}

.property-details .investment-return {
  margin-top: 16px;
  font-size: 18px;
  font-weight: bold;
}

.property-details .highlight {
  color: #3b82f6;
}
.property-details .details {
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px 30px;
}

.property-details-cards {
  margin-bottom: 20px;
}

@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

.property-details .pulse-loader {
  animation: pulse 1.5s infinite ease-in-out;
  background-color: #e0e0e0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.property-details .property-info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.property-details-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(94, 100, 115, 0.1);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.002em;
  color: #5e6473;
  margin-bottom: 15px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

/* .property-details-card .property-details-tags {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
} */

.property-details-tag-left {
  background-color: rgba(252, 211, 77, 0.4); /* bg-yellow-400 bg-opacity-40 */
  border: 1px solid #fcd34d; /* border-yellow-400 */
  border-radius: 9999px; /* rounded-full */
  padding: 0.25rem 0.75rem; /* px-3 py-1 */
  font-size: 0.875rem; /* text-sm */
  font-weight: bold; /* font-bold */
  color: black;
  display: flex;
  align-items: center;
  gap: 0.25rem; /* gap-1 */
  position: absolute;
  top: 10px;
  left: 10px;
}

.property-details-tag-left .dot {
  width: 0.5rem; /* w-2 */
  height: 0.5rem; /* h-2 */
  background-color: #fcd34d; /* bg-yellow-400 */
  border-radius: 9999px; /* rounded-full */
  margin-right: 0.25rem; /* mr-1 */
}

.property-details-tag-right {
  background-color: #e2e8f0; /* bg-slate-200 */
  border: 1px solid #253256; /* border-dark-blue */
  border-radius: 9999px; /* rounded-full */
  padding: 0.25rem 0.75rem; /* px-3 py-1 */
  font-size: 0.875rem; /* text-sm */
  font-weight: bold; /* font-bold */
  color: black;
  display: flex;
  align-items: center;
  gap: 0.25rem; /* gap-1 */
  position: absolute;
  top: 10px;
  right: 10px;
}

.property-details-tag-right .dot {
  width: 0.5rem; /* w-2 */
  height: 0.5rem; /* h-2 */
  background-color: #253256; /* bg-dark-blue */
  border-radius: 9999px; /* rounded-full */
  margin-right: 0.25rem; /* mr-1 */
}

/* .property-details-card:hover {
  filter: contrast(0.7);
} */

.property-details-card.selected {
  border: 2px solid #14223d;
}

.property-details-card .property-details-image {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.property-details-card .property-details-attributes {
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  width: 100%;
  justify-content: space-between;
}

.property-details-card .property-details-attributes > div {
  align-items: center;
}

.property-details-card .property-details-attributes > .property-details-area {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.property-details-card
  .property-details-attributes
  > .property-details-area
  .property-details-area-separator {
  border-left: 1px solid #0000001a;
  height: 15px;
}

.property-details-progress {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 170px; /* Adjusted to fit content */
}

.funded-tag {
  font-weight: bold;
  align-self: flex-start;
  color: black;
}

.progress-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.property-details-progress-container {
  position: relative;
  width: 100%;
  border-radius: 8px;
  background-color: #eeeeee;
  height: 7px;
  overflow: hidden;
}

.property-details-progress-bar {
  height: 100%;
  background: #ffd000;
  border-radius: 8px; /* Adjusted for both ends rounded */
}

.property-details-progress-text {
  font-weight: bold;
  margin-left: 10px; /* Add some space between the bar and the text */
}

.property-details-card .property-details-content {
  padding: 0 20px 20px 20px;
}

.property-details-card .property-details-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #14223d;
  margin-bottom: 10px;
}

.property-details-card .property-details-description {
  line-height: 150%;
  letter-spacing: 0.005em;
  color: #14223d;
  max-height: 63px;
  overflow: hidden;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.property-details-card .property-details-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.property-details-card .property-details-content .property-details-price > div {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  margin: 20px 0;
  gap: 7px;
  background: #ffffff;
  border: 1px solid #eceff5;
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #14223d;
}
.property-details-card
  .property-details-content
  .property-details-price-esitmated
  > div {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  margin: 10px 0;
  gap: 7px;
  background: #ffffff;
  border: 1px solid #eceff5;
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #14223d;
}
.property-details-card .property-details-price-esitmated {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.property-details-price-circle-yellow {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffd000;
}
.property-details-price-circle-blue {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #14223d;
}

.property-details-card
  .property-details-price
  .property-details-price-circle-yellow {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffd000;
}

.property-details-card
  .property-details-price
  .property-details-price-circle-blue {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #14223d;
}

.property-details-card .property-details-financials {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px;
  gap: 10px;
  background: #f6f7f9;
  border-radius: 8px;
}

.property-details-card .property-details-financials > div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}

.property-details-card
  .property-details-financials
  .property-details-financial-value {
  color: #14223d;
  font-weight: 700;
}

.property-details {
  margin: 50px auto;
}

.card {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: var(--white);
  border: 1.5px solid #e2e8f0;
  border-radius: 8px;
  box-shadow:
    0px 0.25px 1px 0px #0000000a,
    0px 0.6px 2.5px 0px #00000007,
    0px 1.15px 4.75px 0px #00000006,
    0px 2px 8.5px 0px #00000005,
    0px 3.75px 15.85px 0px #00000004,
    0px 9px 38px 0px #00000003;
}

.property-details h2 {
  font-size: 32px;
  text-align: left;
  margin-top: 50px;
}

.property-details .images {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}

.property-details .images img {
  width: 100%;
  height: auto;
}

.property-details .video-container {
  width: 100%;
  height: 605px;
  z-index: 9999;
  background-color: black;
  border-radius: 20px;
  overflow: hidden;
}
.property-details .big-video {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.property-details .images .big-image {
  flex: 1 1 50%;
}

.property-details p {
  margin: 0;
  letter-spacing: 0;
  line-height: 2;
  margin-bottom: 5px;
}

.property-details hr {
  background-color: #e2e8f0;
  border: 0;
  height: 2px;
  margin: 1rem 0;
}

.property-details .bkg-grey {
  background-color: #f6f7f9;
  padding: 20px;
}

.property-details .images .small-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 images per row */
  gap: 10px; /* Adjust the gap between grid images */
  flex: 1 1 50%; /* Adjust the size of the grid image area */
  max-height: 408px;
  overflow: auto;
}

.property-details .docs li {
  list-style: none;
  background-color: #e2e8f0;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: bold;
}

.property-details .docs li:hover {
  background-color: #14223d;
  color: #ffffff;
}

.property-details .docs li a {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.property-details .documents > div {
  cursor: pointer;
  transition: background-color 0.3s;
}

.property-details .documents > div:hover {
  background-color: var(--light-grey);
}

.property-details .add-to-cart {
  position: sticky;
  top: 20px;
  align-self: flex-start;
}

.investment-calculator {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.investment-calculator__title {
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #14223d;
  letter-spacing: normal;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: normal;
}

.investment-calculator__subtitle {
  text-align: center;
  font-size: 1.125rem;
  color: #2d3748;
  font-weight: 300;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.investment-calculator__return {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.investment-calculator__details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.investment-calculator__detail {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.investment-calculator__label {
  font-weight: 300;
  color: #2d3748;
  font-size: 0.875rem;
}

.investment-calculator__value {
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #14223d;
  letter-spacing: normal;
  font-weight: bold;
  line-height: normal;
}

.investment-calculator__sliders {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.investment-calculator__slider {
  margin-bottom: 1.5rem;
}

.investment-calculator__slider-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.investment-calculator__disclaimer {
  background-color: #f6f7f9;
  border-radius: 0.375rem;
  text-align: center;
  padding: 1.5rem;
}

.investment-calculator__disclaimer-text {
  font-weight: 300;
  font-size: 0.75rem;
}

.roi-label {
  font-size: 0.875rem; /* Equivalent to text-sm */
  font-weight: 700;
  color: #1e1e1e;
}

.roi-value {
  display: flex;
  align-items: center;
  gap: 0.25rem; /* Equivalent to gap-1 */
}

.roi-percentage,
.funded-days {
  font-size: 0.875rem;
  font-weight: 800;
  color: #047857;
  text-align: right;
  padding-right: 10px;
}

.property-details-details-container {
  display: flex;
  gap: 1rem; /* Equivalent to gap-4 */
  margin: 1rem 0; /* Equivalent to my-4 */
}

.price-info {
  background-color: rgba(
    252,
    211,
    77,
    0.4
  ); /* Equivalent to bg-yellow-400 bg-opacity-40 */
  border: 1px solid #fcd34d; /* Equivalent to border-yellow-400 */
  border-radius: 9999px; /* Equivalent to rounded-full */
  padding: 0.25rem 0.75rem; /* Equivalent to px-3 py-1 */
  font-size: 0.875rem; /* Equivalent to text-sm */
  font-weight: bold; /* Equivalent to font-bold */
  color: black;
}

.price-info-text {
  display: flex;
  align-items: center;
}

.price-dot {
  width: 0.5rem; /* Equivalent to w-2 */
  height: 0.5rem; /* Equivalent to h-2 */
  background-color: #fcd34d; /* Equivalent to bg-yellow-400 */
  border-radius: 9999px; /* Equivalent to rounded-full */
  margin-right: 0.25rem; /* Equivalent to mr-1 */
}

.rental-info {
  background-color: #e2e8f0; /* Equivalent to bg-slate-200 */
  border: 1px solid #253256; /* Equivalent to border-dark-blue */
  color: black;
  border-radius: 9999px; /* Equivalent to rounded-full */
  padding: 0.25rem 0.75rem; /* Equivalent to px-3 py-1 */
  font-size: 0.875rem; /* Equivalent to text-sm */
  font-weight: bold; /* Equivalent to font-bold */
}

.rental-info-text {
  display: flex;
  align-items: center;
}

.rental-dot {
  width: 0.5rem; /* Equivalent to w-2 */
  height: 0.5rem; /* Equivalent to h-2 */
  background-color: #253256; /* Equivalent to bg-dark-blue */
  border-radius: 9999px; /* Equivalent to rounded-full */
  margin-right: 0.25rem; /* Equivalent to mr-1 */
}

.map > div {
  border-radius: 20px;
}
.property-details .big-image .fade-out {
  opacity: 0;
}

.property-details .big-image .fade-in {
  opacity: 1;
}

.property-details .image-navigation {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}
.property-details .nav-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.property-details .fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1080px) {
  .property-details .gradient-overlay {
    bottom: 4%;
  }

  .property-details .image-details {
    bottom: 9%;
  }

  .property-details .image-details {
    left: 0;
  }

  .property-details .property-info-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    padding-left: 2rem;
  }

  .property-details .property-price {
    margin: 0;
  }

  .property-details .header-content {
    flex-direction: column;
    justify-content: center;
  }

  .property-details .property-heading {
    font-size: 40px;
  }
}

@media (max-width: 1078px) {
  .property-details .gradient-overlay {
    bottom: 0%;
  }

  .property-details .image-details {
    bottom: 4%;
  }
  .property-details .property-heading {
    font-size: 38px;
    margin-bottom: 30px;
  }
  .property-details {
    padding: 5%;
  }
  .property-details .header {
    margin-top: -70px;
  }
  .property-details .images {
    flex-direction: column;
  }
  .property-details .property-price-value {
    font-size: 20px;
  }
  .property-details .images .big-image,
  .property-details .images .small-images {
    flex: 1 1 auto;
  }
  .property-details .images .small-images {
    grid-template-columns: 1fr; /* Stack images vertically on smaller screens */
  }
  .property-details .docs {
    flex-direction: column;
  }
  .property-details .docs li a {
    justify-content: flex-start;
  }

  .property-details .small-images {
    display: none;
  }

  .property-details .section-container {
    flex-direction: column;
  }

  .property-details .big-image-container {
    width: 100%;
  }

  .property-details .detail-cards {
    width: 100%;
  }

  .financials-grid {
    flex-direction: column;
    gap: 5px !important;
  }

  .property-details .vertical-line {
    height: 1px !important;
  }

  .tab-buttons {
    padding: 0 !important;
    overflow-x: auto;
    width: 100% !important;
  }
}

@media (max-width: 420px) {
  .property-details .property-heading {
    font-size: 28px;
    /* margin-bottom: 20px; */
  }
  .property-details .big-image {
    height: 430px;
  }
}
/* Custom scrollbar styles for the property-details page */
.property-details ::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.property-details ::-webkit-scrollbar-thumb {
  background-color: #ffd000; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
  border: 3px solid #f6f7f9; /* Adds some padding around the thumb */
}

.property-details ::-webkit-scrollbar-thumb:hover {
  background-color: #e2af17; /* Color of the thumb when hovered */
}

.property-details ::-webkit-scrollbar-track {
  background-color: #f3f4f6; /* Color of the scrollbar track */
  border-radius: 6px; /* Roundness of the scrollbar track */
}

.property-details {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: #ffd000 #f3f4f6; /* thumb color and track color */
}

.tab-container {
  width: 100%;
  margin: 0 auto;
}

.tab-buttons {
  display: flex;
  justify-content: space-between;
  background-color: #f3f1f1;
  margin-top: 20px;
  padding: 12px 32px;
  gap: 20px;
  width: 45%;
  border-radius: 12px;
}

.tab-button {
  flex: 1;
  padding: 10px 24px;
  border: none;
  background: none;
  color: #818181;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 12px;
  transition:
    color 0.2s,
    background-color 0.2s;
  /* transition: color 0.2s, background-color 0.2s, border-radius 0.2s; */
}

.tab-button:hover {
  color: black;
  background-color: #cdcdd1;
  border-radius: 12px;
}

.tab-button.active {
  color: black;
  font-weight: bold;
  background-color: #ffd000;
  border-radius: 8px;
}

.tab-content h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #111827;
}

.tab-content p {
  font-size: 16px;
  color: #4b5563;
  line-height: 1.5;
}

.tab-details {
  margin-top: 24px;
  border-radius: 16px;
  background: #f0f4ff;
  padding: 20px 40px;
}

.tab-details-title {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
}

.tab-details-text {
  padding-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.36px;
  max-height: auto;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  white-space: pre-line;
}

.read-more-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  color: #111827;
  margin-top: 8px;
}

.tab-details-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.tab-details-text.collapsed {
  max-height: 200px;
}

.tab-details-text.expanded {
  max-height: max-content;
}

.read-more-toggle img {
  margin-right: 8px;
  transition: transform 0.3s ease;
}

.read-more-toggle span {
  font-size: 16px;
}

.financials-container {
  background-color: #1a2b46; /* Dark blue background */
  color: #f8f8f8; /* White text */
  padding: 16px 24px;
  border-radius: 20px;
  margin-top: 20px;
}

.financials-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.financials-title {
  color: var(--Secondary-Color, #f8f8f8);
  font-family: "Plus Jakarta Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
}

.financials-estimation {
  display: flex;
  align-items: center;
  border: 1px solid var(--Brand-Color, #ffd000);
  background: rgba(255, 208, 0, 0.12);
  border-radius: 100px;
  color: var(--Brand-Color, #ffd000);
  padding: 8px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.financials-estimation .dot {
  width: 8px;
  height: 8px;
  background-color: #ffd000;
  border-radius: 50%;
  margin-right: 8px;
}

.financials-grid {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.financials-section {
  flex: 1;
  padding: 0 20px;
}

.financials-subtitle {
  color: var(--Secondary-Color, #f8f8f8);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.36px;
  opacity: 0.6;
  padding: 6px 0;
  margin-bottom: 1rem;
}

.financials-item {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px 0;
  color: var(--Secondary-Color, #f8f8f8);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
}

.financials-value {
  color: var(--Secondary-Color, #f8f8f8);
  text-align: right;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
  opacity: 0.7;
}

.financials-item-bold {
  font-weight: 600;
}

.financials-divider {
  border: none;
  border-top: 1px solid #3b4a67;
  margin: 16px 0;
}

.property-details .vertical-line {
  border-left: 1px solid rgba(177, 177, 177, 0.24);
  height: 240px;
  transform: translateX(-50%);
}

.documents-container {
  margin-top: 20px;
}

.documents-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.documents-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  list-style-type: none;
  padding: 0;
}

.document-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.document-item {
  position: relative;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(94, 100, 115, 0.1);
  display: flex;
  align-items: center;
  width: 300px;
}

.document-gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(50% 50% at 50% 50%, #fef5f5 0%, #fddcdc 100%);
  filter: blur(50px);
  border-radius: 12px;
  z-index: -1; /* Ensure the gradient is behind the content */
}

.document-link {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-decoration: none;
  color: inherit;
  position: relative;
  width: 100%;
  justify-content: space-between;
}

.document-icon {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.document-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.document-name {
  font-size: 16px;
  font-weight: 600;
  color: #14223d;
  margin: 4px 0;
  padding-top: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.document-size {
  font-size: 14px;
  color: #6b7280;
}

.download-icon {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}
.property-description p {
  line-height: 1.6; /* Same as the editor */
  margin-bottom: 1.5rem; /* Same as the editor */
}

.property-description h1,
.property-description h2 {
  margin-top: 2rem; /* Consistent with editor */
  margin-bottom: 1.5rem; /* Consistent with editor */
}

.rotate-180 {
  transform: rotate(180deg);
}

/* for Responsive Behavior of skeleton*/
@media (max-width: 768px) {
  .hidden-mobile-skeleton {
    display: none;
  }

  .big-image-skeleton {
    width: 100% !important;
  }

  .detail-cards-skeleton {
    width: 100% !important;
  }
}
