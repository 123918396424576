/* Modal background */
.maintenance-modal-bg {
    background-color: #FFF4E4;
    z-index: 999;
}

/* Modal header styles */
.maintenance-modal-header {
    display: flex;
    justify-content: flex-start;
}

/* Icon wrapper */
.maintenance-icon-wrapper {
    width: 3rem;
    height: 3rem;
    padding: 0.7rem;
    margin-right: 14px;
    border: 2px solid white;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.maintenance-icon {
    width: 100%;
    height: 100%;
}

/* Modal title */
.maintenance-modal-title {
    font-weight: bold;
    color: #14223D;
    font-size: 1.125rem; /* 18px */
    letter-spacing: -0.36px;
    line-height: 1.5; /* Adjust for multiline text */
}

/* Divider */
.maintenance-divider {
    border-bottom: 1px solid #DFDEEE;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

/* Modal content */
.maintenance-modal-content {
    color: #768195;
    letter-spacing: -0.36px;
    font-size: 1rem; /* 16px */
    font-weight: 600;
    line-height: 1.75rem;

}

/* Highlighted text */
.maintenance-highlight {
    color: #14223D; /* deep blue */
}

/* Modal button */
.maintenance-modal-button {
    margin-top: 0.5rem;
    background-color: #14223D;
    color: white;
    font-weight: bold;
    letter-spacing: normal;
    font-size: 1rem; /* 16px */
    padding: 0.75rem 3rem;
    border-radius: 0.5rem;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.2s;
}

.maintenance-modal-button:hover {
    background-color: #172645;
}
